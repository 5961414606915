<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.depositoryRecords") }}</li>
      </ul>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll"
    >
      <div class="main main_page">
        <div class="Order_list">
          <div class="Order_list_box">
            <div class="no_data" v-if="!listItems || listItems.length == 0">
              <p>{{ t("common.message.noRecords") }}</p>
            </div>
            <div
              v-else
              class="bata"
              :key="index"
              v-for="(item, index) in listItems"
            >
              <ul>
                <li>
                  {{ t("profile.label.time") }}:
                  <b>{{ formatServerDateTime(item.createDate) }}</b>
                </li>
                <li>
                  {{ t("profile.label.serialNumber") }}:
                  <b>{{ item.id }}</b>
                </li>
                <template v-if="locale === 'en'">
                  <li
                    class="iconfont icon-Processing"
                    title="processing"
                    v-if="item.delFlag == 'N'"
                  ></li>
                  <li
                    v-else
                    class="iconfont icon-wancheng c-999"
                    title="Carry out"
                  ></li>
                </template>

                <template v-if="locale === 'pb'">
                  <li
                    class="iconfont icon-processando"
                    title="processing"
                    v-if="item.delFlag == 'N'"
                  ></li>
                  <li
                    v-else
                    class="iconfont icon-completado c-999"
                    title="Carry out"
                  ></li>
                </template>
              </ul>
              <ul class="ui_ai">
                <li>
                  {{ t("profile.label.days") }}:
                  <span>{{ item.days }}</span>
                </li>
                <li>
                  {{ t("profile.label.expectedProfits") }}:
                  <span>
                    {{ formatPercentage(item.minEarnings) }}-{{
                      formatPercentage(item.maxEarnings)
                    }}
                  </span>
                </li>
                <li>
                  {{ t("profile.label.amount") }}:
                  <span class="c-red">
                    {{ formatMoney(item.initialAmount, false) }}
                  </span>
                </li>
                <li>
                  {{ t("profile.label.profits") }}:
                  <span class="c-red">
                    {{ formatMoney(item.amount - item.initialAmount, false) }}
                  </span>
                </li>
                <li>
                  {{ t("profile.label.expireDate") }}:
                  <span>{{ formatServerDateTime(item.endDate) }}</span>
                </li>
                <li>
                  {{ t("profile.label.fee") }}:
                  <span>{{ formatPercentage(item.fee) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { ref, watch, reactive, onMounted } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
import {
  formatMoney,
  formatMoneySign,
  formatServerDateTime,
  formatPercentage,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
export default {
  components: { BetterScroll },
  setup() {
    const { t, locale } = useI18n();
    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    const alreadyReq = ref(false);
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      api
        .userBuyRecordList({
          current: currentPage.value + 1,
          size: 10,
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    return {
      t,
      locale,
      scrollRef,
      formatMoney,
      formatMoneySign,
      formatPercentage,
      formatServerDateTime,
      formatShortDateTime,
      listItems,
      fetchMorePage,
      alreadyReq,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;

  .Order_list {
    padding: 0;
  }
}
</style>
